import React, { useEffect, useRef, useState } from "react";
import MainComponent from "../MainComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "./InputField";
import Arrow from "../../assets/images/arrow-circle-down.svg";
import ArrowSIMP from "../../assets/images/arrow-bottom.svg";
import Repeat from "../../assets/images/repeat.svg";
import Assign from "../../assets/images/assign.svg";
import Calendar from "../../assets/images/Calendar.svg";
import Asana from "../../assets/images/asana.png";
import AddIcon from "../../assets/images/add.svg";
import StrategyPlanSidebar from "./XLExecutionPlanSidebar";
import Select from "react-select";
import keys from "../../utils/helpInfoKeys.json";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import {
  getRandomColor,
  createImageFromInitials,
  StepProgressBar,
} from "../Utils";
import {
  createStatus,
  createProgressBar,
  createPriority,
} from "./WorkboardProgress";
import UserProfile from "../../assets/images/user-profile.svg";
import ChevronRight from "../../assets/images/Chevron-right.svg";
import TaskSidebar from "./TaskSidebar";
import {
  getEmployeeData,
  getExistingFunctions,
  getExportWorkboardCsvfile,
  getMasterTaskPriorityAndStatusData,
  getTaskDetails,
  getXLExecutionPlanDetails,
  postStrategyFunctions,
} from "../../store/actions";
import "./XLExecutionPlan.css";
import "./WorkBoard.css";
import DisabledGoalSelector from "./DisabledGoalSelector";
import CommentSection from "../../utils/CommentSection";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Workboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [empData, setEmpData] = useState(null);
  const [collapsedItems, setCollapsedItems] = useState({});
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskDueDate, setNewTaskDueDate] = useState(null);
  const [isTaskSidebarOpen, setIsTaskSidebarOpen] = useState(false);
  const [highlightedRowIndex, setHighlightedRowIndex] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedFunctionStyle, setSelectedFunctionStyle] = useState(null);
  const [isNewTask, setIsNewTask] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState("");
  const [selectedTaskMilestoneId, setSelectedTaskMilestoneId] = useState("");
  const [taskIndex, setTaskIndex] = useState("");
  const [isSubTaskVisible, setIsSubTaskVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [milestoneData, setMilestoneData] = useState([]);
  const [functionData, setFunctionData] = useState([]);
  const [newTaskOwner, setNewTaskOwner] = useState("");
  const [currentImperativeIndex, setCurrentImperativeIndex] = useState();
  const [currentMilestoneIndex, setCurrentMilestoneIndex] = useState();
  const [currentTaskIndex, setCurrentTaskIndex] = useState();
  const [mileStonesData, setMileStonesData] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false); // Controls main dropdown visibility
  const [isNestedVisible, setNestedVisible] = useState(false); // Controls nested options visibility
  const [empId, setEmpId] = useState("");
  const fetchgetExportWorkboardCsvFile = useSelector(
    (state) => state.fetchgetExportWorkboardCsvFile
  );
  const dropdownRef = useRef(null); // Create a reference for the dropdown
  useEffect(() => {
    const response = JSON.parse(localStorage.getItem("userDetails"));
    const employe_Id = response?.data?.emp_id;
    setEmpId(employe_Id);
  }, [localStorage.getItem("userDetails")]);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const toggleNested = () => {
    setNestedVisible(!isNestedVisible);
  };

  useEffect(() => {
    if (
      fetchgetExportWorkboardCsvFile &&
      fetchgetExportWorkboardCsvFile.message
    ) {
      const csvString = fetchgetExportWorkboardCsvFile.message;
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "tasks_export.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setDropdownVisible(false);
      setNestedVisible(false);
    }
  }, [fetchgetExportWorkboardCsvFile]);
  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false); // Close the dropdown if user clicks outside
        setNestedVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const selectedBI = useSelector((state) => state.selectedBI);
  const getExistingFunctionsData = useSelector(
    (state) => state.getExistingFunctionsData
  );
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const renderTooltip = (props) => (
    <Tooltip id="username-tooltip" {...props}>
      Actions
    </Tooltip>
  );
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [biName, setBIName] = useState(null);

  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];

  const strategyPlanData = useSelector((state) => state.strategyPlanData);
  const getMasterTasksPriorityAndStatusDetails = useSelector(
    (state) => state.getMasterTasksPriorityAndStatusDetails
  );
  const isFunctionDataFetched = useSelector(
    (state) => state.isFunctionDataFetched
  );
  const postStrategyFunctionDetails = useSelector(
    (state) => state.postStrategyFunctionDetails
  );

  const [imparativeData, setImperativeData] = useState([]);
  const [showNewTaskInput, setShowNewTaskInput] = useState(
    Array(imparativeData.length).fill(false)
  );

  const tools = [{ name: "Asana", value: "asana", imgSrc: Asana }];
  const filters_data = [
    { name: "All Tasks", value: "1" },
    { name: "My Tasks", value: "2" },
  ];
  const [radioValue, setRadioValue] = useState(
    localStorage.getItem("workboard_filter")
  );
  useEffect(() => {
    if (!localStorage.getItem("workboard_filter")) {
      localStorage.setItem("workboard_filter", "2");
      setRadioValue(localStorage.getItem("workboard_filter"))
    }
  }, []);
  useEffect(() => {
    if (radioValue === "1") {
      dispatch(
        getXLExecutionPlanDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          localStorage.getItem("selectedfunction")
        )
      );
    } else if (radioValue === "2") {
      dispatch(
        getXLExecutionPlanDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          localStorage.getItem("selectedfunction"),
          "my_tasks"
        )
      );
    }
  }, [radioValue]);
  useEffect(() => {
    if (selectedBI) {
      setBIName(selectedBI.label);
    }
  }, [selectedBI]);

  useEffect(() => {
    if (getExistingFunctionsData && getExistingFunctionsData.new_fun) {
      setFunctionData(getExistingFunctionsData.new_fun);
      if (getExistingFunctionsData.new_fun.length > 0) {
        const selectedfun = localStorage.getItem("selectedfunction");
        if (selectedfun) {
          const selected = getExistingFunctionsData.new_fun.some((item)=>item.id === selectedfun)
          if (selected){
            handleSelectFunction(localStorage.getItem("selectedfunction"));
          }else{
            handleSelectFunction(getExistingFunctionsData.new_fun[0].id);
            localStorage.setItem("selectedfunction", getExistingFunctionsData.new_fun[0].id);
          }
        } else {
          handleSelectFunction(getExistingFunctionsData.new_fun[0].id);
          localStorage.setItem("selectedfunction", getExistingFunctionsData.new_fun[0].id);
        }
      }
    }
  }, [getExistingFunctionsData]);

  useEffect(() => {
    if (
      getEmployeeDetails &&
      getEmployeeDetails.emp_data &&
      getEmployeeDetails.emp_data.length > 0
    ) {
      setEmpData(
        getEmployeeDetails.emp_data.map((obj) => {
          return {
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          };
        })
      );
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    if (getMasterTasksPriorityAndStatusDetails) {
      const transformedStatusOptions =
        getMasterTasksPriorityAndStatusDetails.status_dt.map((status) => ({
          value: status,
          label: status,
        }));
      setStatusData(transformedStatusOptions);
      const transformedPriorityOptions =
        getMasterTasksPriorityAndStatusDetails.priority_dt.map((priority) => ({
          value: priority,
          label: priority,
        }));
      setPriorityData(transformedPriorityOptions);
      const transformedMilestoneOptions =
        getMasterTasksPriorityAndStatusDetails.milestone.map((mile) => ({
          value: mile.value,
          label: mile.label,
        }));
      setMileStonesData(transformedMilestoneOptions);
    }
  }, [getMasterTasksPriorityAndStatusDetails]);

  useEffect(() => {
    if (
      strategyPlanData &&
      strategyPlanData.functions &&
      strategyPlanData.functions.length > 0 &&
      strategyPlanData.functions[0].strategic_imperative
    ) {
      setImperativeData(strategyPlanData.functions[0].strategic_imperative);
    }
  }, [strategyPlanData]);

  useEffect(
    (isFunctionDataFetched) => {
      if (
        postStrategyFunctionDetails &&
        postStrategyFunctionDetails.response &&
        postStrategyFunctionDetails.stage_type === "task"
      ) {
        if (postStrategyFunctionDetails.action === "add") {
          postStrategyFunctionDetails.response.map((item) => {
            const updatedImpData = [...imparativeData];
            const newTask = {
              name: item.value,
              id: item.key,
              status: item.dt_status,
              exp_end_date: item.due_date,
              assigned_to: item.assigned_to,
              priority: item.priority,
              progress: item.progress,
              description: item.description,
              collaberators: item.collaberators,
              sub_tasks: [],
            };
            updatedImpData[currentImperativeIndex] &&
              updatedImpData[currentImperativeIndex].milestones[
                currentMilestoneIndex
              ].tasks.push(newTask);
            setImperativeData(updatedImpData);
          });
          window.location.reload();
        } else if (postStrategyFunctionDetails.action === "edit") {
          const updatedImpData = [...imparativeData];
          const existingTasks =
            updatedImpData[currentImperativeIndex] &&
            updatedImpData[currentImperativeIndex].milestones[
              currentMilestoneIndex
            ].tasks[currentTaskIndex];
          postStrategyFunctionDetails.response.map((item) => {
            updatedImpData[currentImperativeIndex].milestones[
              currentMilestoneIndex
            ].tasks[currentTaskIndex] = {
              ...existingTasks,
              name: item.value,
              status: item.dt_status,
              exp_end_date: item.due_date,
              assigned_to: item.assigned_to,
              priority: item.priority,
              progress: item.progress,
              description: item.description,
              collaberators: item.collaberators,
              sub_tasks: item.sub_tasks,
            };
            setImperativeData(updatedImpData);
          });
          window.location.reload();
        }
      }
    },
    [isFunctionDataFetched, postStrategyFunctionDetails]
  );

  useEffect(() => {
    // Open all imperatives by default
    if (imparativeData) {
      imparativeData.forEach((item) => {
        item.isCollapsed = false;
      });
    }
    dispatch(
      getExistingFunctions(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        true
      )
    );
    dispatch(getEmployeeData(localStorage.getItem("bi_id")));
  }, []);

  useEffect(() => {
    if (selectedFunction) {
      dispatch(
        getMasterTaskPriorityAndStatusData(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          selectedFunction
        )
      );
    }
  }, [selectedFunction]);
  const handleClick = () => {
    navigate("/bitracker");
  };
  const handleBack = () => {
    navigate("/executionplan");
  };

  const openTaskNav = (
    task,
    breadcrumb,
    milesid,
    milestoneIndex,
    imperativeIndex,
    taskIndex,
    functionalgoal_id
  ) => {
    setCurrentTaskIndex(taskIndex);
    setIsSubTaskVisible(true);
    setCurrentImperativeIndex(imperativeIndex);
    setCurrentMilestoneIndex(milestoneIndex);
    if (task) {
      setIsNewTask(false);
      dispatch(getTaskDetails(task.id));
      setSelectedTaskId(task.id);
    } else {
      setSelectedTaskMilestoneId(milesid);
      setIsNewTask(true);
      setSelectedTaskId(false);
    }

    setBreadcrumbs(breadcrumb);
    setIsTaskSidebarOpen(true);
  };

  const closeTaskNav = () => {
    setHighlightedRowIndex(null);
    setIsTaskSidebarOpen(false);
  };

  const handleSelectFunction = (selectedfunction) => {
    setSelectedFunction(selectedfunction);
    localStorage.setItem("selectedfunction", selectedfunction);
    setSelectedFunctionStyle(selectedfunction);
    if (radioValue === "1") {
      dispatch(
        getXLExecutionPlanDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          localStorage.getItem("selectedfunction")
        )
      );
    } else if (radioValue === "2") {
      dispatch(
        getXLExecutionPlanDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          localStorage.getItem("selectedfunction"),
          "my_tasks"
        )
      );
    }
  };

  const handleCollapseToggle = (impIndex) => {
    setImperativeData((prevImperativeData) => {
      const newList = [...prevImperativeData];
      newList[impIndex].isCollapsed = !newList[impIndex].isCollapsed; // Toggle the collapse state for the specific function
      return newList;
    });
  };

  const handleCollapseToggleMilestone = (milestoneIndex) => {
    setCollapsedItems((prevCollapsedItems) => ({
      ...prevCollapsedItems,
      [milestoneIndex]: !prevCollapsedItems[milestoneIndex],
    }));
  };

  const handleTaskDataChange = (
    imperativeIndex,
    milestoneIndex,
    taskIndex,
    field,
    data
  ) => {
    const updatedImpData = [...imparativeData];
    updatedImpData[imperativeIndex].milestones[milestoneIndex].tasks[taskIndex][
      field
    ] = data;
    setImperativeData(updatedImpData);
    setTaskIndex(taskIndex);
  };
  const handleToggleTaskEditing = (
    imperativeIndex,
    milestoneIndex,
    taskIndex,
    editing
  ) => {
    const updatedImpData = [...imparativeData];
    updatedImpData[imperativeIndex].milestones[milestoneIndex].tasks[
      taskIndex
    ].isEditing = editing;
    setImperativeData(updatedImpData);
  };

  const handleAddNewTask = (imperativeIndex, milestoneIndex, milestoneId) => {
    setCurrentImperativeIndex(imperativeIndex);
    setCurrentMilestoneIndex(milestoneIndex);
    let data = [
      {
        parent_task_id: milestoneId,
        task_name: newTaskName,
        assigned_to: [],
        due_date: "",
        status: "Open",
        priority: "High",
        progress: 0,
        description: "",
        collaberators: [],
        is_active: true,
        task_id: false,
      },
    ];
    dispatch(postStrategyFunctions(data, "task", false, false, false, true));

    // Clear the new task inputs and hide the input section
    setNewTaskName("");
    setNewTaskDueDate(null);
    const updatedShowNewTaskInput = [...showNewTaskInput];
    updatedShowNewTaskInput[milestoneId] = false;
    setShowNewTaskInput(updatedShowNewTaskInput);
  };

  const handleStepClick = (stepIndex) => {
    // setCurrentStep(stepIndex);
  };

  const handleTaskDelete = () => {
    const updatedImpData = [...imparativeData];
    updatedImpData[currentImperativeIndex] &&
      updatedImpData[currentImperativeIndex].milestones[
        currentMilestoneIndex
      ].tasks.splice(currentTaskIndex, 1);
    setImperativeData(updatedImpData);
    let data = [
      {
        task_id: selectedTaskId,
        is_active: false,
      },
    ];
    dispatch(postStrategyFunctions(data, "task", false, false, false, true));
    closeTaskNav();
  };

  const handleChevronClick = (
    milestoneItem,
    breadcrumb,
    impIndex,
    milestoneIndex
  ) => {
    setMilestoneData(milestoneItem);
    setBreadcrumbs(breadcrumb);
    setCurrentImperativeIndex(impIndex);
    setCurrentMilestoneIndex(milestoneIndex);
  };

  const handleMilestoneEdit = (
    functionIndex,
    impIndex,
    milestoneIndex,
    milestone_data,
    updatedAssignedData
  ) => {
    const updatedImpData = [...imparativeData];

    const existingMilestone =
      updatedImpData[impIndex].milestones[milestoneIndex];
    updatedImpData[impIndex].milestones[milestoneIndex] = {
      ...existingMilestone,
      name: milestone_data[0].milestone,
      status: milestone_data[0].status,
      due_date: milestone_data[0].due_date,
      priority: milestone_data[0].priority,
      assigned_to: updatedAssignedData,
      progress: milestone_data[0].progress,
      description: milestone_data[0].description,
      collaberators: milestone_data[0].collaberators,
    };
    setImperativeData(updatedImpData);
    dispatch(
      postStrategyFunctions(milestone_data, "milestone", false, false, true)
    );
  };

  const handleMilestoneDelete = (
    functionIndex,
    simpIndex,
    milestoneIndex,
    milestoneId
  ) => {
    const updatedImpData = [...imparativeData];
    updatedImpData[simpIndex].milestones.splice(milestoneIndex, 1);
    setImperativeData(updatedImpData);
    let data = [
      {
        milestone_id: milestoneId,
        is_active: false,
      },
    ];
    dispatch(postStrategyFunctions(data, "milestone", false, false, true));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 30,
      minHeight: 30,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 30,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 30,
    }),
  };

  const handleFilterChange = (e) => {
    setRadioValue(e.value);
    localStorage.setItem("workboard_filter", e.value);
  };
  const handleCSVButtonClick = (toolvalue) => {
    dispatch(
      getExportWorkboardCsvfile(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        toolvalue
      )
    );
  };
  const content = (
    <>
      <div className="workboard-filter">
        <Select
          required
          styles={customStyles}
          className="select-filter"
          placeholder={
            <span style={{ fontSize: "var(--sub-heading-font-size)" }}>
              Select
            </span>
          }
          options={filters_data}
          onChange={(e) => handleFilterChange(e)}
          isSearchable={true}
          value={filters_data.find((option) => option.value === radioValue)}
          getOptionLabel={(e) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img alt="" src={e.icon} />
              <span
                style={{
                  marginLeft: 5,
                  fontSize: "var(--sub-heading-font-size)",
                }}
              >
                {e.name}
              </span>
            </div>
          )}
        />
      </div>
      <div className="d-flex functiondata" style={{ alignItems: "center" }}>
        <div
          style={{
            width: "fit-content",
            paddingRight: "10px",
            fontWeight: "bold",
            margin: "0",
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          {biName}
        </div>
        {biName && functionData && functionData.length > 0 && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <div
              className="workboard-chevron-down"
              style={{
                backgroundColor: isDropdownVisible ? "#f8f8f8" : "",
              }}
            >
              <button onClick={() => toggleDropdown()}>
                <i className="bi bi-chevron-down"></i>
              </button>
            </div>
          </OverlayTrigger>
        )}

        {functionData &&
          functionData.length > 0 &&
          functionData.map((funItem, funIndex) => (
            <p
              key={funItem.id}
              className="function-button"
              style={{
                // backgroundColor:
                //   funIndex % 3 === 0
                //     ? "#8746f1"
                //     : funIndex % 3 === 1
                //     ? "#51a5f2"
                //     : "#df58c9",
                backgroundColor:
                  selectedFunction === funItem.id ? "#0A5F59" : "transparent",
                color: selectedFunction === funItem.id ? "white" : "black",
                border:
                  selectedFunction === funItem.id
                    ? "none"
                    : "1px solid lightgrey",
                padding: "4px 12px",
                borderRadius: "20px",
                // boxShadow:
                //   selectedFunction === funItem.id
                //     ? "0px 0px 5px 5px #D0BFFF"
                //     : "",
                fontWeight: selectedFunction === funItem.id ? "600" : "400",
                fontSize: "var(--sub-heading-font-size)",
                marginBottom: "0",
              }}
              onClick={(e) => handleSelectFunction(funItem.id, funIndex)}
            >
              {funItem.name}
            </p>
          ))}
      </div>
      <br />
      {functionData && functionData.length > 0 && imparativeData && imparativeData.length > 0 ? (
        imparativeData.map((impItem, impIndex) => (
          <>
            <div className="d-flex align-items-center">
              <img
                src={Arrow}
                width={"24px"}
                style={{
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  transform: impItem.isCollapsed
                    ? "rotate(180deg)"
                    : "rotate(0)",
                }}
                onClick={() => handleCollapseToggle(impIndex)}
              />
              <button
                style={{
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "2px 8px 4px 8px",
                  marginLeft: "8px",
                  fontSize: "var(--sub-heading-font-size)",
                }}
                className={`color${(impIndex % 3) + 1}`}
                type="button"
                onClick={() => handleCollapseToggle(impIndex)}
              >
                {impItem.name}
              </button>

              <div>
                {impItem.assigned_to.length > 0 &&
                impItem.assigned_to.find((item) => item.is_active) ? (
                  <img
                    style={{ margin: "10px" }}
                    alt=""
                    src={createImageFromInitials(
                      30,
                      impItem.assigned_to.find((item) => item.is_active).label,
                      getRandomColor(
                        impItem.assigned_to.find((item) => item.is_active).label
                      )
                    )}
                  />
                ) : (
                  <img alt="" src={UserProfile} style={{ margin: "10px" }} />
                )}
              </div>
              <DisabledGoalSelector
                selectedGoals={
                  impItem &&
                  impItem.goals_dt &&
                  impItem.goals_dt.filter((goal) => goal.is_active)
                }
                options={impItem.goals_dt}
              />
            </div>

            <div
              className={`collapse ${impItem.isCollapsed ? "" : "show"}`}
              style={{ paddingLeft: "22px" }}
            >
              <div className="goals-table">
                <table className="goal-table-data">
                  <thead>
                    <tr>
                      <th className="narrow-heading">Milestones and Tasks</th>
                      <th>Owners</th>
                      <th>Due Date</th>
                      <th>Progress</th>
                      <th>Status</th>
                      <th>Priority</th>
                    </tr>
                  </thead>
                  {impItem.milestones.map((milestoneItem, milestoneIndex) => (
                    <>
                      <tbody>
                        <tr
                          className="milestoneContainer"
                          key={milestoneIndex}
                          style={{
                            textAlign: "left",
                            backgroundColor:
                              highlightedRowIndex === milestoneItem.id
                                ? "#D9F6E4"
                                : "",
                          }}
                        >
                          <td>
                            <div className="milestone">
                              <div
                                className=""
                                key={milestoneIndex}
                                style={{
                                  textAlign: "left",
                                  padding: "6px 0px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  minWidth: "30px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <img
                                    src={ArrowSIMP}
                                    width={"24px"}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      transition: "transform 0.3s ease",
                                      transform: collapsedItems[
                                        milestoneItem.id
                                      ]
                                        ? "rotate(180deg)"
                                        : "rotate(0)",
                                    }}
                                    onClick={() =>
                                      handleCollapseToggleMilestone(
                                        milestoneItem.id
                                      )
                                    }
                                  />
                                  <span style={{ paddingLeft: "5px" }}>
                                    <button
                                      className="workboard-text-ellipsis"
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                      }}
                                      type="button"
                                      onClick={() =>
                                        handleCollapseToggleMilestone(
                                          milestoneItem.id
                                        )
                                      }
                                    >
                                      {milestoneItem.name}
                                    </button>
                                  </span>
                                </div>
                                <img
                                  alt="chevronright"
                                  src={ChevronRight}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIsSidebarOpen(true);
                                    handleChevronClick(
                                      milestoneItem,
                                      impItem.name,
                                      impIndex,
                                      milestoneIndex,
                                      milestoneItem
                                    );
                                    setHighlightedRowIndex(milestoneItem.id);
                                  }}
                                />
                              </div>
                            </div>
                          </td>

                          <td>
                            <div>
                              {milestoneItem.assigned_to.length > 0 &&
                              milestoneItem.assigned_to.find(
                                (item) => item.is_active
                              ) ? (
                                <img
                                  style={{ margin: "10px" }}
                                  alt=""
                                  src={createImageFromInitials(
                                    30,
                                    milestoneItem.assigned_to.find(
                                      (item) => item.is_active
                                    ).label,
                                    getRandomColor(
                                      milestoneItem.assigned_to.find(
                                        (item) => item.is_active
                                      ).label
                                    )
                                  )}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={Assign}
                                  style={{ margin: "10px" }}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // width: "100px",
                              }}
                            >
                              <img
                                alt=""
                                src={Calendar}
                                style={{ margin: "10px" }}
                                // onClick={handleIconClick}
                              />
                              {milestoneItem.exp_end_date ? (
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    width: "100px",
                                  }}
                                >
                                  {milestoneItem.exp_end_date}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    color: "gray",
                                    marginBottom: "0px",
                                    width: "100px",
                                  }}
                                >
                                  Select Date
                                </p>
                              )}
                              {/* <DatePicker
                                    selected={null}
                                    onChange={(date) =>
                                      handleTaskDataChange(
                                        impIndex,
                                        milestoneIndex,
                                        taskIndex,
                                        "due_date",
                                        date
                                      )
                                    }
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select Date"
                                    className="date-picker-input"
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      width: "100px",
                                    }}
                                    disabled
                                  /> */}
                            </div>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <div className="step-percentage">
                              {milestoneItem.progress}%
                            </div>
                          </td>
                          <td>
                            <div style={{ width: "100px" }}>
                              {createStatus(milestoneItem.status)}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: "100px" }}>
                              {createPriority(milestoneItem.priority)}
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            textAlign: "left",
                            backgroundColor: "white",
                          }}
                        >
                          {!showNewTaskInput[milestoneItem.id] ? (
                            <td colSpan="4">
                              {(selectedSubMenuPermission === "write" ||
                                selectedSubMenuPermission === "admin") && (
                                <div
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "30px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <button
                                    style={{
                                      display: "flex",
                                      border: "none",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      alignItems: "center",
                                    }}
                                    type="button"
                                    // onClick={() => setShowNewTaskInput(true)}
                                    // onClick={() => {
                                    //   const updatedShowNewTaskInput = [
                                    //     ...showNewTaskInput,
                                    //   ];
                                    //   updatedShowNewTaskInput[
                                    //     milestoneItem.id
                                    //   ] = true;
                                    //   setShowNewTaskInput(
                                    //     updatedShowNewTaskInput
                                    //   );
                                    // }}
                                    onClick={() => {
                                      openTaskNav(
                                        false,
                                        impItem.name +
                                          " > " +
                                          milestoneItem.name +
                                          " > New Task",
                                        milestoneItem.id,
                                        milestoneIndex,
                                        impIndex,
                                        taskIndex,
                                        impItem.id
                                      );
                                    }}
                                  >
                                    <img
                                      src={AddIcon}
                                      width={16}
                                      height={16}
                                      style={{ marginRight: "5px" }}
                                    />
                                    Add Task
                                  </button>
                                </div>
                              )}
                            </td>
                          ) : (
                            <td colSpan="4">
                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <InputField
                                  onChange={(e) =>
                                    setNewTaskName(e.target.value)
                                  }
                                  Close={() => {
                                    const updatedShowNewTaskInput = [
                                      ...showNewTaskInput,
                                    ];
                                    updatedShowNewTaskInput[
                                      milestoneItem.id
                                    ] = false;
                                    setShowNewTaskInput(
                                      updatedShowNewTaskInput
                                    );
                                  }}
                                  handleSubmit={(e) =>
                                    handleAddNewTask(
                                      impIndex,
                                      milestoneIndex,
                                      milestoneItem.id
                                    )
                                  }
                                  placeholder="Write a task name"
                                  name="task"
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                        {milestoneItem.tasks.length > 0 ? (
                          !collapsedItems[milestoneItem.id] &&
                          milestoneItem.tasks.map((tasks, taskIndex) => (
                            <>
                              <tr
                                key={taskIndex}
                                style={{
                                  backgroundColor:
                                    highlightedRowIndex === tasks.id
                                      ? "#D9F6E4"
                                      : "",
                                }}
                              >
                                <td style={{ paddingLeft: "20px !important" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "48px",
                                    }}
                                  >
                                    <img
                                      src={Repeat}
                                      width={"24px"}
                                      style={{ marginRight: "8px" }}
                                    />

                                    {/* <input
                                    className="hover-input"
                                    type="text"
                                    value={tasks.name}
                                    onChange={(e) =>
                                      handleTaskDataChange(
                                        impIndex,
                                        milestoneIndex,
                                        taskIndex,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                    onBlur={() =>
                                      handleToggleTaskEditing(
                                        impIndex,
                                        milestoneIndex,
                                        taskIndex,
                                        false
                                      )
                                    }
                                    style={{
                                      flex: 1,
                                      padding: "6px 8px",
                                      marginRight: "8px",
                                    }}
                                    disabled={
                                      selectedSubMenuPermission === "write" ||
                                      selectedSubMenuPermission === "admin"
                                        ? false
                                        : true
                                    }
                                  /> */}
                                    <div
                                      onBlur={() =>
                                        handleToggleTaskEditing(
                                          impIndex,
                                          milestoneIndex,
                                          taskIndex,
                                          false
                                        )
                                      }
                                      style={{
                                        flex: 1,
                                        padding: "6px 8px",
                                        marginRight: "8px",
                                        textAlign: "left",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        openTaskNav(
                                          tasks,
                                          impItem.name,
                                          milestoneItem.id,
                                          milestoneIndex,
                                          impIndex,
                                          taskIndex
                                        );
                                        setHighlightedRowIndex(tasks.id);
                                      }}
                                    >
                                      {tasks.name}
                                    </div>
                                    <img
                                      alt="chevronright"
                                      src={ChevronRight}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        openTaskNav(
                                          tasks,
                                          impItem.name,
                                          milestoneItem.id,
                                          milestoneIndex,
                                          impIndex,
                                          taskIndex
                                        );
                                        setHighlightedRowIndex(tasks.id);
                                      }}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    {tasks.assigned_to.length &&
                                    tasks.assigned_to.find(
                                      (item) => item.is_active
                                    ) ? (
                                      <img
                                        style={{ margin: "10px" }}
                                        alt=""
                                        src={createImageFromInitials(
                                          30,
                                          tasks.assigned_to.find(
                                            (item) => item.is_active
                                          ).label,
                                          getRandomColor(
                                            tasks.assigned_to.find(
                                              (item) => item.is_active
                                            ).label
                                          )
                                        )}
                                      />
                                    ) : (
                                      <img
                                        alt=""
                                        src={Assign}
                                        style={{ margin: "10px" }}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      // width: "100px",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={Calendar}
                                      style={{ margin: "10px" }}
                                      // onClick={handleIconClick}
                                    />
                                    {tasks.exp_end_date ? (
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          width: "100px",
                                        }}
                                      >
                                        {tasks.exp_end_date}
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          color: "gray",
                                          marginBottom: "0px",
                                          width: "100px",
                                        }}
                                      >
                                        Select Date
                                      </p>
                                    )}
                                    {/* <DatePicker
                                    selected={null}
                                    onChange={(date) =>
                                      handleTaskDataChange(
                                        impIndex,
                                        milestoneIndex,
                                        taskIndex,
                                        "due_date",
                                        date
                                      )
                                    }
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select Date"
                                    className="date-picker-input"
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      width: "100px",
                                    }}
                                    disabled
                                  /> */}
                                  </div>
                                </td>
                                <td>
                                  <div className="step-percentage">
                                    {tasks.progress}%
                                  </div>
                                </td>
                                <td>{createStatus(tasks.status)}</td>
                                <td>{createPriority(tasks.priority)}</td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <div style={{ padding: "10px 40px" }}>
                            No tasks yet.
                          </div>
                        )}

                        {isSidebarOpen && (
                          <StrategyPlanSidebar
                            isEdit={true}
                            data={milestoneData}
                            type={"milestone"}
                            placeholder={"Milestone"}
                            empData={empData}
                            statusData={statusData}
                            priorityData={priorityData}
                            breadcrumbs={breadcrumbs}
                            milestonedataId={milestoneItem.id}
                            impIndex={currentImperativeIndex}
                            milestoneIndex={currentMilestoneIndex}
                            handleMilestoneEdit={handleMilestoneEdit}
                            handleMilestoneDelete={handleMilestoneDelete}
                            onClose={() => {
                              setIsSidebarOpen(false);
                              setHighlightedRowIndex("");
                            }}
                            setIsSidebarOpen={(value) => {
                              setIsSidebarOpen(value);
                            }}
                          />
                        )}
                      </tbody>
                    </>
                  ))}
                </table>
              </div>
            </div>
            {isTaskSidebarOpen && (
              <TaskSidebar
                isNewTask={isNewTask}
                selectedTaskMilestoneId={selectedTaskMilestoneId}
                breadcrumbs={breadcrumbs}
                empData={empData}
                statusData={statusData}
                mileStonesData={mileStonesData}
                priorityData={priorityData}
                selectedTaskId={selectedTaskId}
                onClose={closeTaskNav}
                handleTaskDelete={handleTaskDelete}
                isSubTaskVisible={isSubTaskVisible}
                setIsSubTaskVisible={(value) => setIsSubTaskVisible(value)}
              />
            )}
          </>
        ))
      ) : (
        <div style={{ marginTop: "15vh" }}>
          <p>No tasks yet.</p>
        </div>
      )}
      <div>
        <CommentSection />
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
      {isDropdownVisible && (
        <div
          className="workboard-actions-container"
          ref={dropdownRef}
          style={{
            backgroundColor: isNestedVisible ? "#f8f8f8" : "",
          }}
        >
          <div onClick={toggleNested}>
            <span style={{ paddingRight: "40px" }}>Export CSV</span>
            <i className="bi bi-chevron-right"></i>
          </div>
        </div>
      )}
      {isNestedVisible && (
        <div className="workboard-actions-nested-container" ref={dropdownRef}>
          {tools.map((tool) => (
            <div
              key={tool.name}
              onClick={() => handleCSVButtonClick(tool.value)}
              className="workboard-action-item"
            >
              <img src={tool.imgSrc} width={20} alt={tool.name} />
              <label style={{ marginLeft: "10px" }}>{tool.name}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          showTemplate={true}
          showSelector={true}
          showButtons={true}
          handleNext={handleClick}
          handleBackButton={handleBack}
          // hideBackButton={true}
          showProducts={true}
          breadcrumbParent={"GTM Execution"}
          breadcrumbChild={"Workboard"}
          documentName={"Workboard"}
          helpKey={keys["Workboard"].Name}
          image={"question"}
        />
      </>
    );
  }
}

export default Workboard;
