export const LOADING_START = "LOADING_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOGIN_INITIATE = "USER_LOGIN_INITIATE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const FORGOT_USER_PASSWORD = "FORGOT_USER_PASSWORD";
export const FORGOT_USER_PASSWORD_SUCCESS = "FORGOT_USER_PASSWORD_SUCCESS";
export const SESSION_API_ERROR = "SESSION_API_ERROR";
export const EXCEPTION_ERROR = "EXCEPTION_ERROR";
export const UI_MENU_LIST = "UI_MENU_LIST";
export const UI_MENU_LIST_SUCCESS = "UI_MENU_LIST_SUCCESS";
export const BI_DETAILS = "BI_DETAILS";
export const BI_DETAILS_POST = "BI_POST";
export const BI_DETAILS_SUCCESS = "BI_DETAILS_SUCCESS";
export const BI_DETAILS_LIST_SUCCESS = "BI_DETAILS_LIST_SUCCESS";
export const BI_DETAILS_LIST = "BI_DETAILS_LIST";
export const BI_SECTOR_GET = "BI_SECTOR_GET";
export const BI_SECTOR_POST = "BI_SECTOR_POST";
export const BI_DETAILS_GET_SUCCESS = "BI_DETAILS_GET_SUCCESS";
export const BI_DETAILS_POST_SUCCESS = "BI_DETAILS_POST_SUCCESS";
export const BI_SECTOR_GET_SUCCESS = "BI_SECTOR_GET_SUCCESS";
export const BI_SECTOR_POST_SUCCESS = "BI_SECTOR_POST_SUCCESS";
export const BI_SUB_SECTOR_GET = "BI_SUB_SECTOR_GET";
export const BI_SUB_SECTOR_POST = "BI_SUB_SECTOR_POST";
export const BI_SUB_SECTOR_GET_SUCCESS = "BI_SUB_SECTOR_GET_SUCCESS";
export const BI_SUB_SECTOR_POST_SUCCESS = "BI_SUB_SECTOR_POST_SUCCESS";
export const BI_BUSINESS_TYPE_GET = "BI_BUSINESS_TYPE_GET";
export const BI_BUSINESS_TYPE_POST = "BI_BUSINESS_TYPE_POST";
export const BI_BUSINESS_TYPE_GET_SUCCESS = "BI_BUSINESS_TYPE_GET_SUCCESS";
export const BI_BUSINESS_TYPE_POST_SUCCESS = "BI_BUSINESS_TYPE_POST_SUCCESS";
export const BI_PRODUCT_DETAILS_GET = "BI_PRODUCT_DETAILS_GET";
export const BI_PRODUCT_DETAILS_POST = "BI_PRODUCT_DETAILS_POST";
export const BI_PRODUCT_DETAILS_GET_SUCCESS = "BI_PRODUCT_DETAILS_GET_SUCCESS";
export const BI_PRODUCT_DETAILS_POST_SUCCESS =
  "BI_PRODUCT_DETAILS_POST_SUCCESS";
export const VC_LIST_GET = "VC_LIST_GET";
export const VC_LIST_GET_SUCCESS = "VC_LIST_GET_SUCCESS";
export const VC_SELECTED_GET = "VC_SELECTED_GET";
export const VC_SELECTED_GET_SUCCESS = "VC_SELECTED_GET_SUCCESS";
export const VC_CREATE = "VC_CREATE";
export const VC_CREATE_GET_SUCCESS = "VC_CREATE_GET_SUCCESS";
export const VC_CHANGE_TABLE_DATA = "VC_ADD_TABLE_DATA";
export const VC_CHANGE_TABLE_DATA_SUCCESS = "VC_ADD_TABLE_DATA_SUCCESS";
export const ADD_CUSTOMER_VC_SELECTION = "ADD_CUSTOMER_VC_SELECTION";
export const ADD_CUSTOMER_VC_SELECTION_SUCCESS =
  "ADD_CUSTOMER_VC_SELECTION_SUCCESS";
export const GET_CUSTOMER_VC_SELECTION = "GET_CUSTOMER_VC_SELECTION";
export const GET_CUSTOMER_VC_SELECTION_SUCCESS =
  "GET_CUSTOMER_VC_SELECTION_SUCCESS";
export const MASTER_TO_CUSTOMER_VC = "MASTER_TO_CUSTOMER_VC";
export const MASTER_TO_CUSTOMER_VC_SUCCESS = "MASTER_TO_CUSTOMER_VC_SUCCESS";
export const EDIT_DIMENSIONS = "EDIT_DIMENSIONS";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_DIMENSION = "ADD_DIMENSION";
export const ADD_DIMENSION_SUCCESS = "ADD_DIMENSION_SUCCESS";
export const ADD_CHILD_DIMENSION = "ADD_CHILD_DIMENSION";
export const ADD_CHILD_DIMENSION_SUCCESS = "ADD_CHILD_DIMENSION_SUCCESS";
export const SESSION_EXPIRE_ERROR = "SESSION_EXPIRE_ERROR";
export const GET_ORG_DETAILS = "GET_ORG_DETAILS";
export const GET_ORG_DETAILS_SUCCESS = "GET_ORG_DETAILS_SUCCESS";
export const REMOVE_CUSTOMER_VC_SELECTION = "REMOVE_CUSTOMER_VC_SELECTION";
export const ADD_CURRENT_DOC_URL = "ADD_CURRENT_DOC_URL";
export const ADD_SCREEN_ID = "ADD_SCREEN_ID";
export const ADD_MAIN_SCREEN_ID = "ADD_MAIN_SCREEN_ID";
export const REMOVE_CUSTOMER_VC_SELECTION_SUCCESS =
  "REMOVE_CUSTOMER_VC_SELECTION_SUCCESS";
export const GET_PRODUCTS_DETAILS = "GET_PRODUCTS_DATA";
export const GET_PRODUCTS_DETAILS_SUCCESS = "GET_PRODUCTS_DATA_SUCCESS";
export const GET_ANSOFF_MATRIX_DETAILS = "GET_ANSOFF_MATRIX_DETAILS";
export const GET_ANSOFF_MATRIX_DETAILS_SUCCESS =
  "GET_ANSOFF_MATRIX_DETAILS_SUCCESS";
export const GET_MARKET_SIZE_DETAILS = "GET_MARKET_SIZE_DETAILS";
export const GET_MARKET_SIZE_DETAILS_SUCCESS =
  "GET_MARKET_SIZE_DETAILS_SUCCESS";
export const POST_MARKET_SIZE_DETAILS = "POST_MARKET_SIZE_DETAILS";
export const POST_MARKET_SIZE_DETAILS_SUCCESS =
  "POST_MARKET_SIZE_DETAILS_SUCCESS";

export const GET_FETAURE_TYPE_DETAILS = "GET_FETAURE_TYPE_DETAILS";
export const GET_FETAURE_TYPE_DETAILS_SUCCESS =
  "GET_FETAURE_TYPE_DETAILS_SUCCESS";
export const POST_FETAURE_DETAILS = "POST_FETAURE_DETAILS";
export const POST_FETAURE_DETAILS_SUCCESS = "POST_FETAURE_DETAILS_SUCCESS";
export const GET_COMPETITOR_DETAILS = "GET_COMPETITOR_DETAILS";
export const GET_COMPETITOR_DETAILS_SUCCESS = "GET_COMPETITOR_DETAILS_SUCCESS";
export const POST_COMPETITOR_DETAILS = "POST_COMPETITOR_DETAILS";
export const POST_COMPETITOR_DETAILS_SUCCESS =
  "POST_COMPETITOR_DETAILS_SUCCESS";
export const GET_COMPETITOR_ANALYSIS_DETAILS =
  "GET_COMPETITOR_ANALYSIS_DETAILS";
export const GET_COMPETITOR_ANALYSIS_DETAILS_SUCCESS =
  "GET_COMPETITOR_ANALYSIS_DETAILS_SUCCESS";
export const POST_COMPETITOR_ANALYSIS_DETAILS =
  "POST_COMPETITOR_ANALYSIS_DETAILS";
export const POST_COMPETITOR_ANALYSIS_DETAILS_SUCCESS =
  "POST_COMPETITOR_ANALYSIS_DETAILS_SUCCESS";
export const GET_METRICS = "GET_METRICS";
export const GET_METRICS_SUCCESS = "GET_METRICS_SUCCESS";
export const POST_METRICS = "POST_METRICS";
export const POST_METRICS_SUCCESS = "POST_METRICS_SUCCESS";
export const GET_MS_DOCS = "GET_MS_DOCS";
export const GET_MS_DOCS_SUCCESS = "GET_MS_DOCS_SUCCESS";
export const POST_MS_DOCS = "POST_MS_DOCS";
export const POST_MS_DOCS_SUCCESS = "POST_MS_DOCS_SUCCESS";
export const GET_NOTES = "GET_NOTES";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const POST_NOTES = "POST_NOTES";
export const POST_NOTES_SUCCESS = "POST_NOTES_SUCCESS";
export const RESET_EXCEPTION_ERROR = "RESET_EXCEPTION_ERROR";
export const GET_MEETING = "GET_MEETING";
export const GET_MEETING_SUCCESS = "GET_MEETING_SUCCESS";
export const POST_MEETING = "POST_MEETING";
export const POST_MEETING_SUCCESS = "POST_MEETING_SUCCESS";
export const GET_KNOWLEDGE_BASE = "GET_KNOWLEDGE_BASE";
export const GET_KNOWLEDGE_BASE_SUCCESS = "GET_KNOWLEDGE_BASE_SUCCESS";
export const POST_COPY_KNOWLEDGE_BASE = "POST_COPY_KNOWLEDGE_BASE";
export const POST_COPY_KNOWLEDGE_BASE_SUCCESS =
  "POST_COPY_KNOWLEDGE_BASE_SUCCESS";
export const GET_XLEXECUTION_PLAN_DETAILS_SUCCESS =
  "GET_XLEXECUTION_PLAN_DETAILS_SUCCESS";
export const GET_XLEXECUTION_PLAN_DETAILS = "GET_XLEXECUTION_PLAN_DETAILS";
// export const POST_COPY_MASTER_FUNCTIONS_XLEXECUTION =
//   "POST_COPY_MASTER_FUNCTIONS_XLEXECUTION";
export const POST_EDIT_STRATEGY_OBJECTIVE = "POST_EDIT_STRATEGY_OBJECTIVE";
export const POST_STRATEGY_GOALS = "POST_STRATEGY_GOALS";
export const POST_STRATEGY_GOAL_SUCCESS = "POST_STRATEGY_GOAL_SUCCESS";
export const POST_STRATEGY_FUNCTIONS = "POST_STRATEGY_FUNCTIONS";
export const POST_STRATEGY_FUNCTIONS_SUCCESS =
  "POST_STRATEGY_FUNCTIONS_SUCCESS";
// export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
// export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_EMPLOYEE_DATA = "GET_EMPLOYEE_DATA";
export const GET_EMPLOYEE_DATA_SUCCESS = "GET_EMPLOYEE_DATA_SUCCESS";
export const GET_TASKS_PRIORITY_STATUS = "GET_TASKS_PRIORITY_STATUS";
export const GET_TASKS_PRIORITY_STATUS_SUCCESS =
  "GET_TASKS_PRIORITY_STATUS_SUCCESS";
export const GET_INITIATIVE_TRACKER_DETAILS = "GET_INITIATIVE_TRACKER_DETAILS";
export const GET_INITIATIVE_TRACKER_DETAILS_SUCCESS =
  "GET_INITIATIVE_TRACKER_DETAILS_SUCCESS";
export const GET_EXISTING_GOALS = "GET_EXISTING_GOALS";
export const GET_EXISTING_GOALS_SUCCESS = "GET_EXISTING_GOALS_SUCCESS";
export const GET_EXISTING_FUNCTIONS = "GET_EXISTING_FUNCTIONS";
export const GET_EXISTING_FUNCTIONS_SUCCESS = "GET_EXISTING_FUNCTIONS_SUCCESS";
export const GET_CHAT_DETAILS_INFO = "GET_CHAT_DETAILS_INFO";
export const GET_CHAT_DETAILS_INFO_SUCCESS = "GET_CHAT_DETAILS_INFO_SUCCESS";
export const GET_SINGLE_TASK_DATA = "GET_SINGLE_TASK_DATA";
export const GET_TASK_DATA_SUCCESS = "GET_TASK_DATA";
export const POST_TASK_COMMENTS = "POST_TASK_COMMENTS";
export const POST_TASK_COMMENTS_SUCCESS = "POST_TASK_COMMENTS_SUCCESS";
export const POST_FUNGOAL_DEPENDECIES = "POST_FUNGOAL_DEPENDECIES";
export const POST_FUNGOAL_DEPENDECIES_SUCCESS =
  "POST_FUNGOAL_DEPENDECIES_SUCCESS";
export const POST_FUNGOAL_KEYDECISIONS = "POST_FUNGOAL_KEYDECISIONS";
export const POST_FUNGOAL_KEYDECISIONS_SUCCESS =
  "POST_FUNGOAL_KEYDECISIONS_SUCCESS";
export const POST_FUNGOAL_RISK_ISSUES = "POST_FUNGOAL_RISK_ISSUES";
export const POST_FUNGOAL_RISK_ISSUES_SUCCESS =
  "POST_FUNGOAL_RISK_ISSUES_SUCCESS";
export const GET_FUNCTIONAL_GOAL_DETAILS = "GET_FUNCTIONAL_GOAL_DETAILS";
export const GET_FUNCTIONAL_GOAL_DETAILS_SUCCESS =
  "GET_FUNCTIONAL_GOAL_DETAILS_SUCCESS";
export const GET_FUNGOAL_MEETING_REVIEW_DATA =
  "GET_FUNGOAL_MEETING_REVIEW_DATA";
export const GET_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS =
  "GET_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS";
export const POST_FUNGOAL_MEETING_REVIEW_DATA =
  "POST_FUNGOAL_MEETING_REVIEW_DATA";
export const POST_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS =
  "POST_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS";
export const POST_FUNGOALREVIEW_MEETING_PROGRESS =
  "POST_FUNGOALREVIEW_MEETING_PROGRESS";
export const POST_FUNGOALREVIEW_MEETING_PROGRESS_SUCCESS =
  "POST_FUNGOALREVIEW_MEETING_PROGRESS_SUCCESS";
export const POST_FUNGOALREVIEW_MEETING_NEXTSTEPS =
  "POST_FUNGOALREVIEW_MEETING_NEXTSTEPS";
export const POST_FUNGOALREVIEW_MEETING_NEXTSTEPS_SUCCESS =
  "POST_FUNGOALREVIEW_MEETING_NEXTSTEPS_SUCCESS";
export const GET_EXECUTION_READINESS_FUNCTIONALAREA =
  "GET_EXECUTION_READINESS_FUNCTIONALAREA";
export const GET_EXECUTION_READINESS_FUNCTION =
  "GET_EXECUTION_READINESS_FUNCTION";
export const GET_EXECUTION_READINESS_FUNCTION_SUCCESS =
  "GET_EXECUTION_READINESS_FUNCTION_SUCCESS";
export const GET_STRATEGY_READINESS_FUNCTION =
  "GET_STRATEGY_READINESS_FUNCTION";
export const GET_STRATEGY_READINESS_FUNCTION_SUCCESS =
  "GET_STRATEGY_READINESS_FUNCTION_SUCCESS";
export const POST_STRATEGY_READINESS_FUNCTION =
  "POST_STRATEGY_READINESS_FUNCTION";
export const POST_STRATEGY_READINESS_FUNCTION_SUCCESS =
  "POST_STRATEGY_READINESS_FUNCTION_SUCCESS";
export const GET_DASHBOARD_STATE_DETAILS = "GET_DASHBOARD_STATE_DETAILS";
export const GET_DASHBOARD_STATE_DETAILS_SUCCESS =
  "GET_DASHBOARD_STATE_DETAILS_SUCCESS";
export const POST_DASHBOARD_STATE_DETAILS = "POST_DASHBOARD_STATE_DETAILS";
export const POST_DASHBOARD_STATE_DETAILS_SUCCESS =
  "POST_DASHBOARD_STATE_DETAILS_SUCCESS";
export const GET_CALENDER_MEETING_DETAILS = "GET_CALENDER_MEETING_DETAILS";
export const GET_CALENDER_MEETING_DETAILS_SUCCESS =
  "GET_CALENDER_MEETING_DETAILS_SUCCESS";
export const GET_DASHBOARD_TASK_DETAILS = "GET_DASHBOARD_TASK_DETAILS";
export const GET_DASHBOARD_TASK_DETAILS_SUCCESS =
  "GET_DASHBOARD_TASK_DETAILS_SUCCESS";
export const GET_ECOSYSTEM_MAPPING_SECTION_DETAILS =
  "GET_ECOSYSTEM_MAPPING_SECTION_DETAILS";
export const GET_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS =
  "GET_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS";
export const POST_ECOSYSTEM_MAPPING_SECTION_DETAILS =
  "POST_ECOSYSTEM_MAPPING_SECTION_DETAILS";
export const POST_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS =
  "POST_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS";
export const GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA =
  "GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA";
export const GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA_SUCCESS =
  "GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA_SUCCESS";
export const POST_EXECUTION_READINESS_FUNCTIONALAREA =
  "POST_EXECUTION_READINESS_FUNCTIONALAREA";
export const POST_EXECUTION_READINESS_FUNCTIONALAREA_SUCCESS =
  "POST_EXECUTION_READINESS_FUNCTIONALAREA_SUCCESS";
export const GET_NOTIFICATION_DETAILS = "GET_NOTIFICATION_DETAILS";
export const GET_NOTIFICATION_DETAILS_SUCCESS =
  "GET_NOTIFICATION_DETAILS_SUCCESS";
export const POST_NOTIFICATION_DETAILS = "POST_NOTIFICATION_DETAILS";
export const POST_NOTIFICATION_DETAILS_SUCCESS =
  "POST_NOTIFICATION_DETAILS_SUCCESS";
export const GET_FILE_MANAGER_DETAILS = "GET_FILE_MANAGER_DETAILS";
export const GET_FILE_MANAGER_DETAILS_SUCCESS =
  "GET_FILE_MANAGER_DETAILS_SUCCESS";
export const POST_FILE_MANAGER_DETAILS = "POST_FILE_MANAGER_DETAILS";
export const POST_FILE_MANAGER_DETAILS_SUCCESS =
  "POST_FILE_MANAGER_DETAILS_SUCCESS";
export const SET_SELECTED_NEW_BI = "SET_SELECTED_NEW_BI";
export const GET_ANSOFF_MATRIX_GRID_DETAILS = "GET_ANSOFF_MATRIX_GRID_DETAILS";
export const GET_ANSOFF_MATRIX_GRID_DETAILS_SUCCESS =
  "GET_ANSOFF_MATRIX_GRID_DETAILS_SUCCESS";
export const POST_ANSOFF_MATRIX_DETAILS = "POST_ANSOFF_MATRIX_DETAILS";
export const POST_ANSOFF_MATRIX_DETAILS_SUCCESS =
  "POST_ANSOFF_MATRIX_DETAILS_SUCCESS";
export const POST_EXECUTION_READINESS_DETAILS =
  "POST_EXECUTION_READINESS_DETAILS";
export const POST_EXECUTION_READINESS_DETAILS_SUCCESS =
  "POST_EXECUTION_READINESS_DETAILSSUCCESS";
export const GET_EXECUTION_READINESS_REVIEW_DETAILS =
  "GET_EXECUTION_READINESS_REVIEW_DETAILS";
export const GET_EXECUTION_READINESS_REVIEW_DETAILS_SUCCESS =
  "GET_EXECUTION_READINESS_REVIEW_DETAILS_SUCCESS";
export const POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS =
  "POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS";
export const POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS =
  "POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS";
export const POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS =
  "POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS";
export const POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS =
  "POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS";
export const POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS =
  "POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS";
export const POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS_SUCCESS =
  "POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS_SUCCESS";
export const GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS =
  "GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS";
export const GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS_SUCCESS =
  "GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS_SUCCESS";

export const GET_STRATEGY_READINESS_FUNCTIONALAREA =
  "GET_STRATEGY_READINESS_FUNCTIONALAREA";

// export const POST_STRATEGY_READINESS_FUNCTIONALAREA =
//   "POST_STRATEGY_READINESS_FUNCTIONALAREA";
// export const POST_STRATEGY_READINESS_FUNCTIONALAREA_SUCCESS =
//   "POST_STRATEGY_READINESS_FUNCTIONALAREA_SUCCESS";

export const GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA =
  "GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA";
export const GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA_SUCCESS =
  "GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA_SUCCESS";

export const POST_STRATEGY_READINESS_DETAILS =
  "POST_STRATEGY_READINESS_DETAILS";
export const POST_STRATEGY_READINESS_DETAILS_SUCCESS =
  "POST_STRATEGY_READINESS_DETAILS_SUCCESS";

export const GET_STRATEGY_READINESS_REVIEW_DETAILS =
  "GET_STRATEGY_READINESS_REVIEW_DETAILS";
export const GET_STRATEGY_READINESS_REVIEW_DETAILS_SUCCESS =
  "GET_STRATEGY_READINESS_REVIEW_DETAILS_SUCCESS";

export const POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS =
  "POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS";
export const POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS =
  "POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS";

export const POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS =
  "POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS";
export const POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS =
  "POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS";
export const SET_SELECTED_HELP_INFO_SIDEBAR = "SET_SELECTED_HELP_INFO_SIDEBAR";
export const SET_SELECTED_PERMISSION = "SET_SELECTED_PERMISSION";
export const SET_SELECTED_SUB_MENU_PERMISSION =
  "SET_SELECTED_SUB_MENU_PERMISSION";
export const POST_FOLDER_MANAGER_ADD_NEW = "POST_FOLDER_MANAGER_ADD_NEW";
export const POST_FOLDER_MANAGER_ADD_NEW_SUCCESS =
  "POST_FOLDER_MANAGER_ADD_NEW_SUCCESS";
export const POST_FOLDER_MANAGER_EDIT = "POST_FOLDER_MANAGER_EDIT";
export const POST_FODER_MANAGER_EDIT_SUCCESS =
  "POST_FODER_MANAGER_EDIT_SUCCESS";
export const POST_FOLDER_MANAGER_DELETE = "POST_FOLDER_MANAGER_DELETE";
export const POST_FOLDER_MANAGER_DELETE_SUCCESS =
  "POST_FOLDER_MANAGER_DELETE_SUCCESS";
export const POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER =
  "POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER";
export const POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER_SUCCESS =
  "POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER_SUCCESS";
export const GET_DRIVE_FILES = "GET_DRIVE_FILES";
export const GET_DRIVE_FILES_SUCCESS = "GET_DRIVE_FILES_SUCCESS";
export const GET_PROJECT_SUMMARY_DETAILS = "GET_PROJECT_SUMMARY_DETAILS";
export const GET_PROJECT_SUMMARY_DETAILS_SUCCESS =
  "GET_PROJECT_SUMMARY_DETAILS_SUCCESS";

export const POST_LOCAL_FOLDER_MANAGER = "POST_LOCAL_FOLDER_MANAGER";
export const POST_LOCAL_FOLDER_MANAGER_SUCCESS =
  "POST_LOCAL_FOLDER_MANAGER_SUCCESS";
export const GET_LOCAL_FOLDER_MANAGER = "GET_LOCAL_FOLDER_MANAGER";
export const GET_LOCAL_FOLDER_MANAGER_SUCCESS =
  "GET_LOCAL_FOLDER_MANAGER_SUCCESS";

export const POST_LOCAL_FOLDER_MANAGER_FILE_DELETE =
  "POST_LOCAL_FOLDER_MANAGER_FILE_DELETE";
export const POST_LOCAL_FOLDER_MANAGER_FILE_DELETE_SUCCESS =
  "POST_LOCAL_FOLDER_MANAGER_FILE_DELETE_SUCCESS";

export const GET_SCREEN_COMMENTS = "GET_SCREEN_COMMENTS";
export const GET_SCREEN_COMMENTS_SUCCESS = "GET_SCREEN_COMMENTS_SUCCESS";
export const POST_SCREEN_COMMENTS = "POST_SCREEN_COMMENTS";
export const POST_SCREEN_COMMENTS_SUCCESS = "POST_SCREEN_COMMENTS_SUCCESS";
export const SET_TEMPLATE_BTN_SELECTED = "SET_TEMPLATE_BTN_SELECTED";

export const GET_BI_FGOAL_STATUS = "GET_BI_FGOAL_STATUS";
export const GET_BI_FGOAL_STATUS_SUCCESS = "GET_BI_FGOAL_STATUS_SUCCESS";
export const POST_BI_FGOAL_STATUS = "POST_BI_FGOAL_STATUS";
export const POST_BI_FGOAL_STATUS_SUCCESS = "POST_BI_FGOAL_STATUS_SUCCESS";

export const GET_GLOBAL_SERACH_FILTER = "GET_GLOBAL_SERACH_FILTER";
export const GET_GLOBAL_SERACH_FILTER_SUCCESS =
  "GET_GLOBAL_SERACH_FILTER_SUCCESS";
export const GET_IS_MAINTENANCE_MODE = "GET_IS_MAINTENANCE_MODE";
export const GET_IS_MAINTENANCE_MODE_SUCCESS =
  "GET_IS_MAINTENANCE_MODE_SUCCESS";

export const IS_MAINTENANCE_MODE = "IS_MAINTENANCE_MODE";

export const GET_XLSTRATEGYPLAN_DETAILS = "GET_XLSTRATEGYPLAN_DETAILS";
export const GET_XLSTRATEGYPLAN_DETAILS_SUCCESS =
  "GET_XLSTRATEGYPLAN_DETAILS_SUCCESS";
export const POST_XLSTRATEGYPLAN_DETAILS = "POST_XLSTRATEGYPLAN_DETAILS";
export const POST_XLSTRATEGYPLAN_DETAILS_SUCCESS =
  "POST_XLSTRATEGYPLAN_DETAILS_SUCCESS";
export const GET_EXPORT_WORBOARD_CSV_FILE = "GET_EXPORT_WORBOARD_CSV_FILE";
export const GET_EXPORT_WORBOARD_CSV_FILE_SUCCESS =
  "GET_EXPORT_WORBOARD_CSV_FILE_SUCCESS";