import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import "./CommentSection.css";

import { Button } from "react-bootstrap";
import EditIcon from "../assets/images/Edit.svg";
import DeleteIcon from "../assets/images/trash.svg";
import { getScreenComments, postScreenComments } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
const CommentItemComponent = ({
  comment,
  onDelete,
  onSaveEdit,
  currentUser,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(comment.content);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const menuRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(comment.content);
    setMenuOpen(false); // Close the menu
  };

  const handleSaveEdit = (id, desc) => {
    onSaveEdit(id, desc);
    setIsEditing(false);
    setMenuOpen(false); // Close the menu
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="comment-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="">
        {/* <img style={{ margin: "10px" }} alt="" src={comment.icon} /> */}
        {currentUser === comment?.comment_by[0].id
          ? "You commented on this" + " " + comment.creation
          : comment?.comment_by[0].name +
            " commented on this " +
            comment.creation}
      </div>
      {isEditing ? (
        <>
          <div className="textarea" style={{ position: "relative" }}>
            <textarea
              className="comment-textarea"
              rows={3}
              style={{ marginLeft: "50px" }}
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                style={{
                  width: "fit-content",
                  backgroundColor: "transparent",
                  color: "black",
                  fontSize: "var(--sub-heading-font-size)",
                  border: "1px solid #ccc",
                  padding: "5px 10px",
                  marginRight: "10px",
                }}
                onClick={handleCancelEdit}
                type="submit"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSaveEdit(comment.name, editedText)}
                type="submit"
                style={{
                  width: "fit-content",
                  backgroundColor: "rgb(10, 95, 89)",
                  fontSize: "var(--sub-heading-font-size)",
                  padding: "5px 10px",
                  marginRight: "10px",
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="comment">
          <div style={{ marginLeft: "20px" }}>{comment.content}</div>
          <div className="comment-icons">
            {comment.comment_by &&
              currentUser === comment.comment_by[0].id &&
              isHovered && (
                <FontAwesomeIcon icon={faEllipsisH} onClick={handleMenuClick} />
              )}
            {isMenuOpen && (
              <div className="menu-options" ref={menuRef}>
                <div className="Edit">
                  <div className="EditOption" onClick={handleEditClick}>
                    <img
                      src={EditIcon}
                      width={"18px"}
                      style={{ marginRight: "10px" }}
                      alt="Edit Icon"
                    />
                    Edit
                  </div>
                  <div
                    className="DeleteOption"
                    onClick={() => onDelete(comment.name)}
                  >
                    <img
                      src={DeleteIcon}
                      width={"18px"}
                      style={{ marginRight: "10px" }}
                      alt="Delete Icon"
                    />
                    Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
// const CommentItemComponent = ({
//   comment,
//   onDelete,
//   onSaveEdit,
//   currentUser,
// }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedText, setEditedText] = useState(comment.content);
//   const [isMenuOpen, setMenuOpen] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);
//   const selectedSubMenuPermission = useSelector(
//     (state) => state.selectedSubMenuPermission
//   );
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };
//   const handleMenuClick = () => {
//     setMenuOpen(!isMenuOpen);
//   };

//   const username = localStorage.getItem("full_name");
//   const handleEditClick = () => {
//     setIsEditing(true);
//   };
//   const handleCancelEdit = () => {
//     setIsEditing(false);
//     setEditedText(comment.content);
//     setMenuOpen(false); // Close the menu
//   };

//   const handleSaveEdit = (id, desc) => {
//     onSaveEdit(id, desc);
//     setIsEditing(false);
//     setMenuOpen(false); // Close the menu
//   };
//   return (
//     <div
//       className="comment-item"
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <div className="">
//         <img style={{ margin: "10px" }} alt="" src={comment.icon} />
//         {currentUser === comment?.comment_by[0].id
//           ? "You commented" + " " + comment.creation
//           : comment?.comment_by[0].name + " commented this " + comment.creation}
//       </div>
//       {isEditing ? (
//         <>
//           <div className="textarea" style={{ position: "relative" }}>
//             <textarea
//               className="comment-textarea"
//               rows={3}
//               style={{ marginLeft: "50px" }}
//               value={editedText}
//               onChange={(e) => setEditedText(e.target.value)}
//             />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "right",
//               }}
//             >
//               <Button
//                 style={{
//                   width: "fit-content",
//                   backgroundColor: "transparent",
//                   color: "black",
//                   fontSize: "var(--sub-heading-font-size)",
//                   border: "1px solid #ccc",
//                   padding: "5px 10px",
//                   marginRight: "10px",
//                 }}
//                 onClick={handleCancelEdit}
//                 type="submit"
//               >
//                 Cancel
//               </Button>
//               <Button
//                 onClick={() => handleSaveEdit(comment.name, editedText)}
//                 type="submit"
//                 style={{
//                   width: "fit-content",
//                   backgroundColor: "rgb(10, 95, 89)",
//                   fontSize: "var(--sub-heading-font-size)",
//                   padding: "5px 10px",
//                   marginRight: "10px",
//                 }}
//               >
//                 Save
//               </Button>
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="comment">
//           <div style={{ marginLeft: "20px" }}>{comment.content}</div>
//           <div className="comment-icons">
//             {comment.comment_by &&
//               currentUser === comment.comment_by[0].id &&
//               isHovered && (
//                 <FontAwesomeIcon icon={faEllipsisH} onClick={handleMenuClick} />
//               )}
//             {isMenuOpen && (
//               <div className="menu-options">
//                 <div className="Edit">
//                   <div className="EditOption" onClick={handleEditClick}>
//                     <img
//                       src={EditIcon}
//                       width={"18px"}
//                       style={{ marginRight: "10px" }}
//                       alt="Edit Icon"
//                     />
//                     Edit
//                   </div>
//                   <div
//                     className="DeleteOption"
//                     onClick={() => onDelete(comment.name)}
//                   >
//                     <img
//                       src={DeleteIcon}
//                       width={"18px"}
//                       style={{ marginRight: "10px" }}
//                       alt="Edit Icon"
//                     />
//                     Delete
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const CommentSection = () => {
  const dispatch = useDispatch();
  const fetchGetScreenCommentsData = useSelector(
    (state) => state.fetchGetScreenCommentsData
  );
  const fetchPostScreenCommentsData = useSelector(
    (state) => state.fetchPostScreenCommentsData
  );
  const username = localStorage.getItem("full_name");
  const [commentedBy, setCommentedBy] = useState("");
  const [content, setContent] = useState("");
  const [comments, setComments] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [currentUser, setCurrentUser] = useState("");
  const biId = localStorage.getItem("bi_id");
  const mainScreenId = localStorage.getItem("main_screen_id");
  const screenId = localStorage.getItem("screen_id");
  useEffect(() => {
    dispatch(getScreenComments(biId, mainScreenId, screenId));
    setComments("");
  }, []);
  useEffect(() => {
    if (fetchGetScreenCommentsData && fetchGetScreenCommentsData.comment) {
      setCurrentUser(fetchGetScreenCommentsData.current_user);
      setComments(fetchGetScreenCommentsData.comment);
    }
  }, [fetchGetScreenCommentsData]);
  useEffect(() => {
    if (
      fetchPostScreenCommentsData &&
      fetchPostScreenCommentsData.status === 200
    ) {
      dispatch(getScreenComments(biId, mainScreenId, screenId));
    }
  }, [fetchPostScreenCommentsData]);
  const handleSaveEditComment = (commentId, newContent) => {
    dispatch(
      postScreenComments(
        newContent,
        biId,
        mainScreenId,
        screenId,
        "edit",
        commentId
      )
    );

    setEditingCommentId(null);
  };

  const handleDelete = (commentId) => {
    dispatch(
      postScreenComments(false, false, false, false, "delete", commentId)
    );
    dispatch(getScreenComments(biId, mainScreenId, screenId));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      postScreenComments(content, biId, mainScreenId, screenId, "add", false)
    );
    setCommentedBy("");
    setContent("");
  };

  return (
    <div className="comment-section">
      <div className="comment-section-heading">Comment Section</div>
      <form className="comment-form" onSubmit={handleSubmit}>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Add a comment..."
          rows="4"
          cols="50"
          className="comment-textarea"
          required
        />
        <br />
        <button type="submit" className="comment-submit-button">
          Comment
        </button>
      </form>
      <div>
        {comments?.length > 0 &&
          comments.map((commentItem) => (
            <CommentItemComponent
              key={commentItem.name}
              comment={commentItem}
              onDelete={handleDelete}
              onSaveEdit={handleSaveEditComment}
              currentUser={currentUser}
            />
          ))}
      </div>
    </div>
  );
};

export default CommentSection;
