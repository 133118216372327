import React, { useState, useEffect } from "react";
import { Form, FormCheck } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { default as ReactSelect } from "react-select";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getMeeting, postMeeting } from "../store/actions/index.js";
import BISelector from "./BISelector";
import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";
import moment from "moment";
import { getRandomColor, createImageFromInitials } from "./Utils.js";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import "./Meeting.css";
import QuillEditor from "../utils/QuillEditor.js";

const NewMeeting = (props) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [meetingId, setMeetingId] = useState("");
  const [empData, setEmpData] = useState([]);
  const [meetingName, setMeetingName] = useState("New Meeting");
  const [owner, setOwner] = useState("");
  const [agenda, setAgenda] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [allDay, setAllDay] = useState(false);
  const [shareWithEveryOne, setShareWithEveryOne] = useState(true);
  const [ownerIcon, setOwnerIcon] = useState("");
  const selectedBI = useSelector((state) => state.selectedBI);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShared, setIsShared] = useState(false);
  const [navigateNotes, setNavigateNotes] = useState(false);
  const postedMeetingData = useSelector((state) => state.postedMeetingData);
  const isBIFetched = useSelector((state) => state.isBIFetched);
  const biDetails = useSelector((state) => state.biDetails);

  const [meetings, setMeetings] = useState([]);
  const isMeetingDataFetched = useSelector(
    (state) => state.isMeetingDataFetched
  );
  const getMeetingData = useSelector((state) => state.getMeetingData);

  const [biData, setBiData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(props.eventDate);
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [repeatThisEvent, setRepeatThisEvent] = useState(false);
  const [meetingRepeatOn, setMeetingRepeatOn] = useState(null);
  const [meetingWeeklyOn, setMeetingWeeklyOn] = useState(null);
  const [showRepeatOption, setShowRepeatOption] = useState(null);
  const [repeatTillDate, setRepeatTillDate] = useState(null);
  const [isOldMeeting, setIsOldMeeting] = useState(props.oldMeeting);
  const repeatData = [
    { value: "", label: "None" },
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ];
  const WeeklyData = [
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];

  const handleMeetingRepeatOnChange = (selectedOption) => {
    setMeetingRepeatOn(selectedOption);
    setShowRepeatOption(selectedOption.value === "Weekly" ? true : false);
  };
  const handleMeetingWeeklyOnChange = (selectedOption) => {
    setMeetingWeeklyOn(selectedOption);
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleRepeatThisEvent = () => {
    setRepeatThisEvent(!repeatThisEvent);
  };
  useEffect(() => {
    if (isBIFetched) {
      if (biDetails) {
        if (biDetails.ok) {
          if (biDetails.data) {
            setBiData(
              biDetails.data.bi_data.map((obj, i) => {
                if (obj.name === localStorage.getItem("bi_id")) {
                  dispatch({
                    type: "SET_SELECTED_BI",
                    payload: {
                      value: obj.name,
                      label: obj.bi_name,
                    },
                  });
                }
                return {
                  value: obj.name,
                  label: obj.bi_name,
                };
              })
            );
          }
        }
      }
    }
    return () => {};
  }, [isBIFetched]);
  useEffect(() => {
    if (isMeetingDataFetched) {
      if (getMeetingData && getMeetingData.meeting_data !== "") {
        const meetings = Array.isArray(getMeetingData?.meeting_data)
          ? getMeetingData.meeting_data.filter(
              (meeting) => meeting.goal_review_id === null
            )
          : [];
        setMeetings(meetings);
        if (getMeetingData.emp_data.length > 0) {
          setEmpData(
            getMeetingData.emp_data.map((obj) => {
              return {
                value: obj.value,
                label: obj.label,
                icon: createImageFromInitials(
                  30,
                  obj.label,
                  getRandomColor(obj.label)
                ),
              };
            })
          );
        }
      }
    }
  }, [isMeetingDataFetched, getMeetingData]);

  const handleAgendaOnChange = (content) => {
    setAgenda(content);
  };

  const handleCloseMeetingPopup = () => {
    onClose();
  };

  const handleBISelect = (e) => {
    localStorage.setItem("bi_id", e.value);
    dispatch(getMeeting(e.value));
    // setSelectedBI(e);
    dispatch({
      type: "SET_SELECTED_BI",
      payload: e,
    });
  };
  const customStylesRepeatOption = {
    control: (provided) => ({
      ...provided,
      width: "82%",
    }),
    menu: (provided) => ({
      ...provided,
      width: "82%",
      zIndex: 999,
    }),
    option: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "200px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "200px",
    }),
    option: (provided) => ({
      ...provided,
      width: "200px",
      zIndex: 999,
    }),
  };
  useEffect(() => {
    if (props.selectedMeeting !== "") {
      const meetingIdToFind = props.selectedMeeting;
      const meetingData = getMeetingData?.meeting_data?.find(
        (meeting) => meeting.meeting_id === meetingIdToFind
      );
      const employeeData = getMeetingData?.emp_data;
      if (meetingData) {
        setMeetingId(meetingData.meeting_id);
        setOwner(meetingData.owner);
        setIsShared(meetingData.is_shared);
        setOwnerIcon(createImageFromInitials(30, owner, getRandomColor(owner)));
        setSelectedRow(meetingData.meeting_id);
        setMeetingName(meetingData.meeting);
        setAgenda(meetingData.agenda);
        setAllDay(meetingData.all_day);
        setShareWithEveryOne(meetingData.share_with_everyone);
        setRepeatThisEvent(meetingData.repeat_this_event);
        setMeetingRepeatOn(meetingData.repeat_on);
        if (meetingData?.repeat_on?.value === "Weekly") {
          setShowRepeatOption(true);
        }
        setMeetingWeeklyOn(meetingData.weekly_data);
        setRepeatTillDate(meetingData.repeat_till);
        localStorage.setItem("note_id", meetingData.notes_id);
        const parsedStartDate = moment(meetingData.start).toDate();
        setSelectedStartDate(parsedStartDate);
        if (meetingData.end) {
          const parsedEndDate = moment(meetingData.end).toDate();
          setSelectedEndDate(parsedEndDate);
        } else {
          setSelectedEndDate("");
        }
        if (!meetingData.is_shared) {
          const selectedEmps =
            employeeData.length > 0 &&
            meetingData.emp.map((empId) => {
              const emp = employeeData.find(
                (employee) => employee.value === empId
              );
              return emp;
            });
          if (selectedEmps.length > 0) {
            const selectedOptions = selectedEmps.map((employee) => ({
              value: employee.value,
              label: employee.label,
              icon: createImageFromInitials(
                30,
                employee.label,
                getRandomColor(employee.label)
              ),
            }));
            setSelectedEmployees(selectedOptions);
          }
        }
      } else {
        console.log(`Meeting with ID ${meetingIdToFind} not found.`);
      }
    }
  }, []);

  useEffect(() => {
    if (postedMeetingData) {
      if (postedMeetingData) {
        localStorage.setItem("note_id", postedMeetingData.note_id);
        if (navigateNotes) {
          navigate("/notes");
          window.location.reload();
        } else {
          console.log("postedMeetingData", postedMeetingData);
        }
      }
    }
  }, [postedMeetingData]);
  // useEffect(() => {
  //   if (navigateNotes) {
  //     navigate("/notes");
  //     window.location.reload();
  //     onClose();
  //   } else {
  //     console.log("false");
  //   }
  // }, [navigateNotes]);
  useEffect(() => {
    const fullname = localStorage.getItem("full_name");
    if (fullname) {
      setOwner(fullname);
      setOwnerIcon(
        createImageFromInitials(30, fullname, getRandomColor(fullname))
      );
    }
  }, []);

  const handleEmployeeChange = (selectedOptions) => {
    const updatedOptions = selectedOptions.filter((option) =>
      empData.some((emp) => emp.value === option.value)
    );
    setSelectedEmployees(updatedOptions);
  };

  const handleRowClick = (row) => {
    setMeetingId(row.meeting_id);
    setIsOldMeeting(true);
    setOwner(row.owner);
    setIsShared(row.is_shared);
    setOwnerIcon(createImageFromInitials(30, row.owner, getRandomColor(owner)));
    setSelectedRow(row.meeting_id);
    setMeetingName(row.meeting);
    setAgenda(row.agenda);
    setAllDay(row.all_day);
    setShareWithEveryOne(row.share_with_everyone);
    setRepeatThisEvent(row.repeat_this_event);
    setMeetingRepeatOn(row.repeat_on);
    if (row.repeat_on && row.repeat_on.value === "Weekly") {
      setShowRepeatOption(true);
    } else {
      setShowRepeatOption(false);
    }
    setMeetingWeeklyOn(row.weekly_data);
    setRepeatTillDate(row.repeat_till);
    localStorage.setItem("note_id", row.notes_id);
    const parsedStartDate = moment(row.start).toDate();
    setSelectedStartDate(parsedStartDate);
    if (row.end) {
      const parsedEndDate = moment(row.end).toDate();
      setSelectedEndDate(parsedEndDate);
    } else {
      setSelectedEndDate("");
    }
    if (!row.is_shared) {
      const selectedEmps =
        empData.length > 0 &&
        row.emp.map((empId) => {
          const emp = empData.find((employee) => employee.value === empId);
          return emp;
        });
      if (selectedEmps && selectedEmps.length > 0) {
        const selectedOptions = selectedEmps.map((employee) => ({
          value: employee.value,
          label: employee.label,
          icon: createImageFromInitials(
            30,
            employee.label,
            getRandomColor(employee.label)
          ),
        }));
        setSelectedEmployees(selectedOptions);
      }
    }
  };

  const handleNotesClick = (navigateFlag) => {
    setNavigateNotes(navigateFlag);
    let emp_list = [];
    if (!shareWithEveryOne) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: selectedEmployees.some(
          (selectedEmp) => selectedEmp.value === emp.value
        ),
      }));
    } else {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: true,
      }));
    }
    if (meetingName !== "") {
      const formattedSelectedStartDate = moment(selectedStartDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      const formattedSelectedEndDate = selectedEndDate
        ? moment(selectedEndDate).format("YYYY-MM-DDTHH:mm:ss")
        : null;
      const meeting_weeklyon_list = WeeklyData.map((week) => ({
        key: week.value,
        is_active:
          meetingWeeklyOn?.length > 0 && meetingRepeatOn.value == "Weekly"
            ? meetingWeeklyOn.some(
                (meeting_week) => meeting_week.value === week.value
              )
            : false,
      }));
      let post_meeting_data = [
        {
          is_shared: isShared ? true : false,
          action: "agenda",
          meeting: meetingName,
          agenda: agenda || "",
          all_day: allDay,
          share_with_everyone: shareWithEveryOne,
          meeting_id: meetingId,
          emp: emp_list,
          startdate: formattedSelectedStartDate,
          enddate: formattedSelectedEndDate,
          repeat_this_event: repeatThisEvent,
          meeting_repeat_on: meetingRepeatOn?.value || "",
          meeting_weekly_on: meeting_weeklyon_list,
          repeat_till: repeatTillDate,
        },
      ];
      dispatch(postMeeting(localStorage.getItem("bi_id"), post_meeting_data));
    }
  };
  useEffect(() => {
    if (postedMeetingData) {
      dispatch(getMeeting(localStorage.getItem("bi_id")));
    }
  }, [postedMeetingData]);

  const handleIsAllDayClick = () => {
    setAllDay(!allDay);
    if (!allDay) {
      setSelectedEndDate("");
    }
  };

  const handleShareWithEveryOneClick = () => {
    setShareWithEveryOne(!shareWithEveryOne);
  };

  const CustomInput = ({ value, onClick }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: 8,
        border: "1px solid #ced4da",
        padding: "0px 8px",
        cursor: "pointer",
        width: "220px",
      }}
      onClick={onClick}
    >
      <input
        type="text"
        value={value}
        readOnly
        placeholder="yyyy-MM-dd HH:mm:ss"
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          background: "transparent",
          margin: "5px 0",
        }}
      />
      <SlCalender />
    </div>
  );

  return (
    <div className="modal-overlay">
      <div
        className="modal-content meeting-popover"
        style={{
          padding: "20px",
          height: "92%",
          overflowX: "hidden",
          overflowY: "auto",
          fontSize: "var(--sub-heading-font-size)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              fontSize: "var(--main-heading)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            {isOldMeeting ? "Meeting Planner" : "Meeting Planner - New Meeting"}
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              zIndex: "100",
            }}
          >
            <BISelector
              showSelector={true}
              selectedBI={selectedBI}
              biData={biData}
              handleSelect={handleBISelect}
              style={{ marginRight: "10px" }}
            />
            <AiOutlineClose
              size={24}
              onClick={handleCloseMeetingPopup}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <hr style={{ borderTop: "1px solid" }} />
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "50%",
              border: "1px solid lightgray",
              background: "#ecf4f3",
            }}
          >
            <table
              className="meeting-table"
              style={{ width: "100%", textAlign: "center" }}
            >
              <thead
                style={{
                  backgroundColor: "#0A5F59",
                  color: "white",
                }}
              >
                <tr>
                  <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Meeting
                  </th>
                  <th>Date and Time</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "10px" }}></tr>
                {meetings?.length > 0 ? (
                  meetings.map((val, key) => (
                    <tr
                      key={key}
                      onClick={() => handleRowClick(val)}
                      style={{
                        cursor: "pointer",
                        border:
                          selectedRow === val.meeting_id
                            ? "2px solid #0A5F59"
                            : "none",
                        backgroundColor:
                          selectedRow === val.meeting_id
                            ? "#D9F6E4"
                            : "transparent",
                        color:
                          selectedRow === val.meeting_id
                            ? "#0A5F59"
                            : "inherit",
                      }}
                    >
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {val.meeting}
                      </td>
                      <td>{val.start}</td>
                      <td>{val.duration}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        height: "250px",
                        verticalAlign: "middle",
                        backgroundColor: "rgb(236, 244, 243)",
                      }}
                    >
                      No meetings available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="modal-body-right"
            style={{ width: "55%", paddingLeft: "20px" }}
          >
            <div className="row">
              <div className="col-12">
                <Form.Group
                  controlId="formMeetingName"
                  style={{
                    marginTop: 0,
                  }}
                >
                  <Form.Control
                    type="text"
                    value={meetingName}
                    onChange={(e) => setMeetingName(e.target.value)}
                    placeholder="Add Meeting Title"
                    required
                    disabled={isShared}
                  />
                </Form.Group>
              </div>
            </div>
            {/* <h6>{meetingName}</h6> */}
            <div className="row">
              <div className="col-6 col-lg-6 col-sm-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <b
                    style={{
                      paddingTop: "5px",
                      marginRight: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      textWrap: "nowrap",
                    }}
                  >
                    Starts On:
                  </b>
                  <div style={{ zIndex: 11 }}>
                    <DatePicker
                      placeholderText="yyyy-MM-dd HH:mm:ss"
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showYearDropdown
                      scrollableYearDropdown
                      customInput={<CustomInput />}
                      showMonthDropdown
                      className="custom-datepicker"
                      yearDropdownItemNumber={1000}
                      disabled={isShared}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-sm-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <b
                    style={{
                      paddingTop: "5px",
                      marginRight: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      textWrap: "nowrap",
                    }}
                  >
                    Ends On:
                  </b>
                  <div style={{ zIndex: 11 }}>
                    <DatePicker
                      placeholderText="yyyy-MM-dd HH:mm:ss"
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showYearDropdown
                      scrollableYearDropdown
                      customInput={<CustomInput />}
                      showMonthDropdown
                      className="custom-datepicker"
                      yearDropdownItemNumber={1000}
                      disabled={isShared}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5 col-sm-12">
                <div
                  // className="custom-datepicker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      paddingRight: "5px",
                    }}
                  >
                    Owner:
                  </div>

                  <img alt="" src={ownerIcon} />
                  {owner}
                </div>
              </div>
            </div>
            <Form.Group controlId="formAdditionalField">
              <strong>
                <Form.Label
                  style={{
                    marginBottom: "0px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Agenda:
                </Form.Label>
              </strong>
              <div style={{ height: "380px" }}>
                <QuillEditor
                  value={agenda}
                  onChange={handleAgendaOnChange}
                  height={300}
                  disabled={isShared}
                />
              </div>
            </Form.Group>
            {!isShared && (
              <Form.Group
                controlId="formCheckbox"
                style={{ marginTop: "10px" }}
              >
                <Form.Check
                  type="checkbox"
                  label="Share with everyone"
                  checked={shareWithEveryOne}
                  onChange={() => handleShareWithEveryOneClick()}
                />
              </Form.Group>
            )}
            {!shareWithEveryOne && !isShared && (
              <Form.Group>
                <span
                  data-toggle="popover"
                  data-trigger="focus"
                  data-content="Please selecet employee(s)"
                >
                  <Select
                    options={empData}
                    value={selectedEmployees}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={e.icon} />
                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                      </div>
                    )}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={handleEmployeeChange}
                    allowSelectAll
                    isDisabled={isShared}
                  />
                </span>
              </Form.Group>
            )}
            <Form.Group
              controlId="formCheckboxallday"
              style={{ paddingTop: "10px" }}
            >
              <Form.Check
                type="checkbox"
                label="All Day"
                checked={allDay}
                onChange={() => handleIsAllDayClick()}
                disabled={isShared}
              />
            </Form.Group>
            <Form.Group
              controlId="formCheckboxrepeatthisevent"
              style={{ paddingTop: "10px" }}
            >
              <Form.Check
                type="checkbox"
                label="Repeat this Event"
                checked={repeatThisEvent}
                onChange={() => handleRepeatThisEvent()}
                disabled={isShared}
              />
            </Form.Group>
            {repeatThisEvent && (
              <>
                <div className="row">
                  <div className="col-6 col-lg-6 col-sm-12">
                    <Form.Group
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "10px",
                      }}
                    >
                      <strong>
                        <Form.Label
                          style={{
                            marginRight: "10px",
                            paddingTop: "5px",
                            textWrap: "nowrap",
                          }}
                        >
                          Repeat On:
                        </Form.Label>
                      </strong>
                      <Select
                        styles={customStyles}
                        options={repeatData}
                        value={meetingRepeatOn}
                        closeMenuOnSelect={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={handleMeetingRepeatOnChange}
                        isMulti={false}
                        placeholder="Repeat On"
                        menuPlacement="top"
                        isDisabled={isShared}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6 col-lg-6 col-sm-12">
                    <Form.Group
                      style={{
                        paddingTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <strong>
                          <Form.Label
                            style={{
                              marginRight: "10px",
                              textWrap: "nowrap",
                            }}
                          >
                            Repeat Till:
                          </Form.Label>
                        </strong>
                        <div
                          style={{
                            minWidth: "200",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ zIndex: 11 }}>
                            <input
                              style={{ width: "200px" }}
                              type="date"
                              className="form-control"
                              value={repeatTillDate}
                              onChange={(e) =>
                                setRepeatTillDate(e.target.value)
                              }
                              disabled={isShared}
                            />
                          </div>
                        </div>
                      </div>
                      <span style={{ fontSize: "var(--text-font-size)" }}>
                        *Leave blank to repeat always
                      </span>
                    </Form.Group>
                  </div>
                </div>
                {showRepeatOption && (
                  <Form.Group
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <strong>
                      <Form.Label
                        style={{
                          marginRight: "10px",
                          textWrap: "nowrap",
                        }}
                      >
                        Repeat Option:
                      </Form.Label>
                    </strong>
                    <div style={{ minWidth: "100%" }}>
                      <Select
                        styles={customStylesRepeatOption}
                        options={WeeklyData}
                        value={meetingWeeklyOn}
                        closeMenuOnSelect={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={handleMeetingWeeklyOnChange}
                        isMulti={true} // Set to true if multiple selections are allowed
                        placeholder="Repeat option"
                        menuPlacement="top"
                        isDisabled={isShared}
                      />
                    </div>
                  </Form.Group>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            marginTop: 20,
            flexWrap: "wrap",
            justifyContent: "space-between",
            bottom: 20,
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <button
            style={{
              width: "40%",
              backgroundColor: !isShared ? "#0A5F59" : "",
              color: "white",
              borderRadius: 10,
              height: 48,
              border: "thin ridge",
              marginRight: 20,
              fontWeight: "var(--heading-font-weight)",
            }}
            onClick={(e) => handleNotesClick(false)}
            disabled={isShared}
          >
            Schedule Meeting
          </button>
          <button
            style={{
              width: "40%",
              backgroundColor: "#0A5F59",
              borderColor: "#0A5F59",
              color: "white",
              borderRadius: 10,
              height: 48,
              border: 0,
              fontWeight: "var(--heading-font-weight)",
            }}
            onClick={(e) => handleNotesClick(true)}
          >
            Go to meeting
            <FaArrowRight style={{ marginLeft: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewMeeting;
