import React from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";

const MeetingSelector = ({ selectedMeeting, meetingData, handleSelect }) => {
  const location = useLocation();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
  };

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div style={{ minWidth: "250px" }}>
      <Select
        styles={customStyles}
        required
        name="meeting_id"
        className={
          location.pathname == "/notes" ? "selectmeeting" : "selectmeeting hide"
        }
        placeholder="Select Meeting"
        value={selectedMeeting}
        options={meetingData}
        onChange={handleSelect}
        isSearchable={true}
        filterOption={customFilter}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: 5 }}>
              <b>{e.label}</b>
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default MeetingSelector;
