import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSummaryDetails } from "../../store/actions";
import keys from "../../utils/helpInfoKeys.json";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";
const Projectsummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [projectData, setProjectData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const fetchProjectSummaryDetails = useSelector(
    (state) => state.fetchProjectSummaryDetails
  );
  useEffect(() => {
    dispatch(getProjectSummaryDetails(localStorage.getItem("bi_id")));
  }, []);

  useEffect(() => {
    if (fetchProjectSummaryDetails) {
      if (
        fetchProjectSummaryDetails.result &&
        fetchProjectSummaryDetails.result.length > 0
      ) {
        setProjectData(fetchProjectSummaryDetails.result[0]);
      }
      if (
        fetchProjectSummaryDetails.chart &&
        fetchProjectSummaryDetails.chart.length > 0
      ) {
        setChartData(fetchProjectSummaryDetails.chart[0]);
      }
    }
  }, [fetchProjectSummaryDetails]);

  const handleBack = () => {
    navigate("/strategyplansummary", { replace: true });
  };

  const handleClick = () => {
    navigate("/ganttchart", { replace: true });
  };

  const headings = Object.keys(projectData);
  const values = Object.values(projectData);
  const chart_values = Object.values(chartData);
  console.log(chart_values);
  const colors = [
    "#e24c4c",
    "#2490ef",
    "#8ccf54",
    "#FF407D",
    "#114232",
    "#FF204E",
    "#800080",
    "#ffa500",
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const data = {
    labels: ["Completion", "Total Tasks", "Tasks Completed", "Tasks Overdue"],
    datasets: [
      {
        data: chart_values,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Project Dashboard",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
  };

  const content = (
    <>
      <div className="projectsummary">
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Project Details
        </div>
        <table style={{ fontSize: "var(--sub-heading-font-size)" }}>
          <thead>
            <tr>
              {headings.map((heading, index) => (
                <td
                  key={index}
                  style={index === 0 ? { width: "200px" } : { width: "50px" }}
                >
                  {heading}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {values.map((value, index) => (
                <td
                  key={index}
                  style={{ color: colors[index], fontWeight: 500 }}
                >
                  {value}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        {/* {headings && headings.length > 0 && values && values.length > 0 ? ( */}
          <div
            style={{
              maxHeight: "500px",
              width: "90%",
              marginTop: "20px",
            }}
          >
            <Bar options={options} data={data} />
          </div>
        {/* ) : (
          <div></div>
        )} */}

        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Reports"}
        breadcrumbChild={"Project Summary"}
        documentName={"Project Summary"}
        helpKey={keys["Project Summary"].Name}
        image={"question"}
      />
    );
  }
};

export default Projectsummary;
