import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Home from "./components/base/Home";
import Dashboard from "./components/base/Dashboard";
import Logout from "./components/base/Logout";
import OrgDetails from "./components/base/onboarding/OrgDetails";
import BIDetails from "./components/base/onboarding/biDetails/BIDetails";
import BIBusinessType from "./components/base/onboarding/vcmapping/BIBusinessType";
import BIProductDetails from "./components/base/onboarding/vcmapping/BIProductDetails";
import Mapping from "./components/base/onboarding/vcmapping/Mapping";
import VCSelection from "./components/base/onboarding/vcmapping/VCSelection";
import Workboard from "./components/base/gtmExecution/Workboard";
import InitiativeTracker from "./components/base/gtmExecution/InitiativeTracker";
import BiReview from "./components/base/gtmExecution/ExecutionPlan";
import AnsoffMatrix from "./components/base/strgyvalidation/AnsoffMatrix";
import MarketSize from "./components/base/strgyvalidation/MarketSize";
import CompetitorAnalysis from "./components/base/strgyvalidation/CompetitorAnalysis";
import CostProjection from "./components/base/strgyvalidation/operatingPlan/CostProjection";
import MarginProjection from "./components/base/strgyvalidation/operatingPlan/MarginProjection";
import RevenueProjection from "./components/base/strgyvalidation/operatingPlan/RevenueProjection";
import TargetMarketShare from "./components/base/strgyvalidation/operatingPlan/TargetMarketShare";
import Metrics from "./components/base/strgyvalidation/Metrics";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./components/store/index";
import Test from "./components/base/Test";
import Test1 from "./components/base/Test1";
import EcosystemMapping, {
  Reports,
} from "./components/base/onboarding/EcosystemMapping/EcosystemMapping";
import Notes from "./components/base/Notes";
import ExecutionReadiness from "./components/base/enterpriseReadiness/ExecutionReadiness";
import StrategyReadiness from "./components/base/enterpriseReadiness/StrategyReadiness";
import MeetingAction from "./components/base/MeetingAction";
import Templates from "./components/base/knowledgeBase/Templates";
import ExecutionReadinessFunction from "./components/base/enterpriseReadiness/ExecutionReadinessFunction";
import StrategyReadinessFunction from "./components/base/enterpriseReadiness/StrategyReadinessFunction";
import OperatingPlan from "./components/base/strgyvalidation/OperatingPlan";
import ListOfNotes from "./components/base/ListOfNotes";
import Ecosystemsummary from "./components/base/reports/Ecosystemsummary";
import Strategyplansummary from "./components/base/reports/Strategyplansummary";
import Projectsummary from "./components/base/reports/Projectsummary";
import Attachmentsummary from "./components/base/reports/Attachmentsummary";
import GanttChart from "./components/base/reports/GanttChart";
import UpdatePassword from "./components/base/UpdatePassword";
import ForgotPassword from "./components/base/ForgotPassword";
import { EditExistingProduct } from "./components/base/ModalCard";
import MaintenancePage from "./standardpages/MaintenancePage";
import XLExecutionPlan from "./components/base/gtmExecution/XLExecutionPlan";
import XLStrategyPlan from "./components/base/gtmExecution/XLStrategyPlan";
import IdleTimeout from "./components/base/IdleTimeout";

const Root = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/orgdetails" element={<OrgDetails />} />
        <Route path="/bidetails" element={<BIDetails />} />
        <Route path="/esmapping" element={<EcosystemMapping />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/bibusinesstype" element={<BIBusinessType />} />
        <Route path="/biproductdetails" element={<BIProductDetails />} />
        <Route path="/vcmapping" element={<Mapping />} />
        <Route path="/vcselection" element={<VCSelection />} />
        <Route path="/bitracker" element={<InitiativeTracker />} />
        <Route path="/workboard" element={<Workboard />} />
        <Route path="/xlstrategymatrix" element={<AnsoffMatrix />} />
        <Route path="/marketsize" element={<MarketSize />} />
        <Route path="/competitors" element={<CompetitorAnalysis />} />
        <Route path="/marketshare" element={<TargetMarketShare />} />
        <Route path="/cost" element={<CostProjection />} />
        <Route path="/revenue" element={<RevenueProjection />} />
        <Route path="/margins" element={<MarginProjection />} />
        <Route path="/metrics" element={<Metrics />} />
        <Route path="/executionplan" element={<XLExecutionPlan />} />
        <Route path="/strategyplan" element={<XLStrategyPlan />} />
        <Route path="/executionreadiness" element={<ExecutionReadiness />} />
        <Route path="/strategyreadiness" element={<StrategyReadiness />} />
        <Route path="/test1" element={<Test1 />} />
        <Route path="/listofnotes" element={<ListOfNotes />} />
        <Route path="/notes" element={<Notes />} />
        <Route path="/meeting/:meetingaction" element={<MeetingAction />} />
        <Route path="/kb/templates" element={<Templates />} />
        <Route path="/bireview" element={<BiReview />} />
        <Route path="/operatingplan" element={<OperatingPlan />} />
        <Route
          path="/executionreadinessfunction"
          element={<ExecutionReadinessFunction />}
        />
        <Route
          path="/strategyreadinessfunction"
          element={<StrategyReadinessFunction />}
        />
        <Route path="/ecosystemsummary" element={<Ecosystemsummary />} />
        <Route path="/strategyplansummary" element={<Strategyplansummary />} />
        <Route path="/projectsummary" element={<Projectsummary />} />
        <Route path="/attachmentsummary" element={<Attachmentsummary />} />
        <Route path="/ganttchart" element={<GanttChart />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
      </Routes>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
      <IdleTimeout/>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
